import { FC } from "react";
import { useTrickData } from "../hooks";

import { Card } from "../components";

export const CardTrick: FC = () => {
  window.history.replaceState(null, "", "/");

  const { status, data } = useTrickData();

  return (
    <main
      className="flex flex-col justify-center items-center max-w-[100vw] w-screen h-[100svh] max-h-[100svh] overflow-hidden"
      style={{ background: "radial-gradient(#313131, #000000)" }}
    >
      {status === "error" && (
        <div className="flex flex-col items-center p-4">
          <h2 className="text-xl text-zinc-300 text-center">
            Something went wrong, please try again later.
          </h2>
        </div>
      )}
      {status !== "error" &&
        new Array(3).fill(0).map((_, i) => <Card key={i} data={data} />)}
    </main>
  );
};
