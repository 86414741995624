import { useParams } from "react-router-dom";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { doc } from "firebase/firestore";

import { useUserIDCookie } from "./useUserIDCookie";

export const useTrickData = () => {
  const { setUserIDCookie, getUserIDCookie } = useUserIDCookie();

  const { id } = useParams<{ id?: string }>();
  if (id) setUserIDCookie(id);

  const cookieId = getUserIDCookie();

  const firestore = useFirestore();
  const ref = doc(firestore, "tricks", id || cookieId || "random");

  const { status, data } = useFirestoreDocData(ref);

  return { status, data };
};
