import { FC } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom/client";

import { getFirestore } from "firebase/firestore";
import { firebaseConfig } from "./services/firebase";

import {
  FirebaseAppProvider,
  FirestoreProvider,
  useFirebaseApp,
} from "reactfire";

import { CardTrick, MagicPage, Speech } from "./pages";

import "./index.css";

const DoNotForgetMe: FC = () => {
  const firestoreInstance = getFirestore(useFirebaseApp());

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <BrowserRouter>
        <Routes>
          <Route path="/testing" element={<Speech />} />
          <Route path="/:id/magic" element={<MagicPage />} />
          <Route path="/:id" element={<CardTrick />} />
          <Route path="*" element={<CardTrick />} />
        </Routes>
      </BrowserRouter>
    </FirestoreProvider>
  );
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  // <React.StrictMode>
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <DoNotForgetMe />
  </FirebaseAppProvider>
  // </React.StrictMode>
);
