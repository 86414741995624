import { getCookie, setCookie } from "react-use-cookie";

const USER_ID_KEY = "USER-ID";

export const useUserIDCookie = () => {
  const setUserIDCookie = (id: string): void => {
    setCookie(USER_ID_KEY, String(id), { days: 1 });
  };

  const getUserIDCookie = (): string | undefined => {
    return getCookie(USER_ID_KEY);
  };

  return { setUserIDCookie, getUserIDCookie };
};
