import { FC } from "react";

export const Spinner: FC = () => (
  <div
    style={{
      border: "1vw solid #404040",
      borderTop: "1vw solid #606060",
      borderRadius: "50%",
      width: "10vw",
      height: "10vw",
    }}
    className="animate-spin"
  />
);
