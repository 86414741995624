import { FC, useEffect, useState } from "react";
import useSpeechToText from "react-hook-speech-to-text";
import NoSleep from "nosleep.js";

import {
  suitMapper,
  numberMapper,
  combinedMapper,
  playingCardRegex,
  combinedRegex,
} from "../helpers";

import "../types/index.d.ts";

const speechConfig = {
  googleApiKey: "AIzaSyBy4QhbxPUdzTrnGjjDGWBGEoA0Wo2YvT4",
  googleCloudRecognitionConfig: {
    languageCode: "nl-NL",
  },
  useLegacyResults: false,
  useOnlyGoogleCloud: true,
  continuous: true,
  timeout: 1200000,
};

export const Speech: FC = () => {
  const [card, setCard] = useState<string>("");

  const isValidCard = (suit: string, number: string): boolean =>
    !!suitMapper[suit] && !!numberMapper[number];

  const {
    error,
    isRecording,
    results,
    startSpeechToText,
    stopSpeechToText,
  }: any = useSpeechToText(speechConfig);

  useEffect(() => {
    const noSleep = new NoSleep();
    noSleep.enable();

    return () => {
      noSleep.disable();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (results.length === 0) return;

    if (results.length > 5) results.shift();

    const { transcript } = results[results.length - 1];

    let match;
    let lastMatch;
    let combinedMatch;
    let lastcombinedMatch;

    while ((match = playingCardRegex.exec(transcript.toLowerCase())) !== null) {
      lastMatch = match;
    }

    if (lastMatch) {
      const suit = lastMatch[1];
      const number = lastMatch[2];

      if (isValidCard(suit, number)) {
        setCard(`${suitMapper[suit]}${numberMapper[number]}`);
      }

      return;
    }

    while (
      (combinedMatch = combinedRegex.exec(transcript.toLowerCase())) !== null
    ) {
      lastcombinedMatch = combinedMatch;
    }

    if (lastcombinedMatch && combinedMapper[lastcombinedMatch[1]]) {
      setCard(`${combinedMapper[lastcombinedMatch[1]]}`);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  if (error) return <p>Web Speech API is not available in this browser 🤷‍</p>;

  return (
    <div className="flex flex-col h-[100svh] w-screen justify-start items-center">
      <button
        onClick={isRecording ? stopSpeechToText : startSpeechToText}
        className={`p-4 rounded mb-8 ${
          isRecording ? "bg-red-800" : "bg-green-800"
        }`}
      >
        {isRecording ? "Stop Recording" : "Start Recording"}
      </button>
      {results.length > 0 && (
        <div className="flex flex-col items-center mb-8">
          <h2 className="text-3xl">Laatste 5 zinnen:</h2>
          <ul className="flex flex-col items-center">
            {results.map((result: any) => (
              <li key={result.timestamp}>{result.transcript}</li>
            ))}
          </ul>
        </div>
      )}
      <h2 className="text-3xl pb-4">Laatst gehoorde kaart:</h2>
      {card ? (
        <div
          id="face"
          className="mb-8 w-[40vh] bg-[url('../public/cards/blank.png')] bg-contain bg-no-repeat bg-center"
        >
          <img
            alt="face"
            className="w-full h-full pointer-events-none select-none "
            src={`../cards/${card.charAt(0)}/${card.substring(1)}.svg`}
          />
        </div>
      ) : (
        <h2>Geen kaart gehoord!</h2>
      )}
    </div>
  );
};
