import { FC, useEffect, useState } from "react";
import { motion } from "framer-motion";

import { cards } from "../helpers";

const variantsFace = {
  normal: {
    rotateY: 180,
    scale: 1,
    transition: {
      rotateY: {
        ease: "easeInOut",
        duration: 0.6,
      },
      scale: {
        ease: "easeInOut",
        duration: 0.6,
      },
    },
  },
  flip: {
    rotateY: 360,
    scale: 1.1,
    transition: {
      rotateY: {
        ease: "easeInOut",
        duration: 0.6,
      },
      scale: {
        ease: "easeInOut",
        duration: 0.6,
      },
    },
  },
};

const variantsBack = {
  normal: {
    rotateY: 0,
    transition: {
      rotateY: {
        ease: "easeInOut",
        duration: 0.6,
      },
    },
  },
  flip: {
    rotateY: 180,
    transition: {
      rotateY: {
        ease: "easeInOut",
        duration: 0.6,
      },
    },
  },
};

export const Card: FC<any> = ({ data }) => {
  const [clicked, isClicked] = useState<boolean>(false);
  const [dragging, isDragging] = useState<boolean>(false);

  const [selectedCard, setSelectedCard] = useState<string>(
    cards[Math.floor(Math.random() * cards.length)]
  );

  useEffect(() => {
    if (data) setSelectedCard(data.card);
  }, [data]);

  return (
    <motion.div
      id="card"
      className="absolute flex h-[60vh] max-h-[450px] w-[43.2vh] max-w-[324px] select-none cursor-pointer rounded-[2vh]"
      style={{
        perspective: "50em",
      }}
      onClick={() => !dragging && isClicked(!clicked)}
      initial={false}
      animate={clicked ? "flip" : "normal"}
      drag
      dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
      onDragStart={() => isDragging(true)}
      onDragEnd={() => isDragging(false)}
    >
      <motion.div
        id="face"
        className="shadow-black shadow-2xl absolute w-full h-full bg-[url('../public/cards/blank.png')] bg-contain bg-no-repeat bg-center rounded-[2vh]"
        style={{ backfaceVisibility: "hidden" }}
        variants={variantsFace}
      >
        <img
          alt="face"
          className="w-full h-full pointer-events-none select-none "
          src={`cards/${selectedCard.charAt(0)}/${selectedCard.substring(
            1
          )}.svg`}
        />
      </motion.div>
      <motion.div
        id="back"
        className="shadow-black shadow-2xl absolute w-full h-full bg-[url('../public/cards/back_red.png')] bg-contain bg-no-repeat bg-center rounded-[2vh]"
        style={{ backfaceVisibility: "hidden" }}
        variants={variantsBack}
      />
    </motion.div>
  );
};
