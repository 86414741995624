import { FC, useState, useEffect } from "react";
import { useFullscreen } from "@mantine/hooks";
import NoSleep from "nosleep.js";

import { useTrickData } from "../hooks";
import { Spinner } from "../components";

export const MagicPage: FC = () => {
  const { toggle, fullscreen } = useFullscreen();

  const [clicked, setClicked] = useState<number>(0);

  useEffect(() => {
    const noSleep = new NoSleep();
    noSleep.enable();
    return () => noSleep.disable();
  }, []);

  useEffect((): void => {
    if (clicked > 3 && fullscreen) {
      setClicked(0);
      toggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clicked]);

  const { status, data } = useTrickData();

  useEffect(() => {
    // if no data is found, redirect to the card trick
    if (status === "success" && !data) window.location.href = "/";
  }, [status, data]);

  return (
    <main
      className="flex flex-col h-[100svh] w-full justify-around items-center bg-zinc-900 transition-all duration-300"
      style={{ background: "radial-gradient(#313131, #000000)" }}
    >
      {status === "loading" && <Spinner />}
      {status === "error" && (
        <div className="flex flex-col items-center p-4">
          <h2 className="text-xl text-zinc-300 text-center">
            Something went wrong, please try again later.
          </h2>
        </div>
      )}
      {!fullscreen && status === "success" && (
        <>
          <div className="flex flex-col items-center p-4">
            <h2 className="text-xl text-zinc-300 text-center">
              Click the wand below to enter magic mode
            </h2>
            <p className="text-zinc-400 italic text-xs pb-8">
              (tap the screen 4 times to close)
            </p>
            <button className="rounded-lg text-6xl text-white" onClick={toggle}>
              🪄
            </button>
          </div>

          <div className="flex flex-col items-center gap-2 w-80 max-w-[500px]">
            <div
              id="face"
              className="mb-8 w-[10vh] bg-[url('../public/cards/blank.png')] bg-contain bg-no-repeat bg-center"
            >
              <img
                alt="face"
                className="w-full h-full pointer-events-none select-none "
                src={`../cards/${data?.card.charAt(0)}/${data?.card.substring(
                  1
                )}.svg`}
              />
            </div>

            <p className="text-zinc-500">To adjust settings, please login!</p>

            <input
              disabled
              type="text"
              className="p-2 bg-zinc-800 text-zinc-300 rounded-lg text-center w-full"
              value={data.linkto}
            />
            <select
              disabled
              value={data.language}
              className="p-2 bg-zinc-800 text-zinc-300  rounded-lg text-center w-full"
            >
              <option value="1">{data.language}</option>
            </select>
          </div>
        </>
      )}
      {fullscreen && status === "success" && (
        <div
          className="flex flex-col h-full w-full bg-black justify-center items-center text-6xl text-white"
          onClick={() => setClicked((prev) => prev + 1)}
        >
          DEBUG: {data.card}
        </div>
      )}
    </main>
  );
};
