export const suitMapper: {
  [key: string]: string;
} = {
  hard: "h",
  harde: "h",
  harte: "h",
  hart: "h",
  harten: "h",
  klaver: "k",
  klaveren: "k",
  "klaar voor": "k",
  ruite: "r",
  ruiten: "r",
  ruiter: "r",
  ruites: "r",
  schoppe: "s",
  schoppen: "s",
  schoppes: "s",
};

export const numberMapper: {
  [key: string]: string;
} = {
  een: "1",
  twee: "2",
  drie: "3",
  vier: "4",
  vijf: "5",
  zes: "6",
  zeven: "7",
  even: "7",
  acht: "8",
  negen: "9",
  tien: "10",
  team: "10",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  10: "10",
  aas: "a",
  heer: "k",
  koning: "k",
  vrouw: "v",
  koningin: "v",
  jack: "b",
  boer: "b",
};

export const combinedMapper: {
  [key: string]: string;
} = {
  klaveraas: "ka",
  klaverkoning: "kk",
  klaverheer: "kk",
  klaverkoningin: "kv",
  klavervrouw: "kv",
  klaverboer: "kb",
  ruitenaas: "ra",
  ruitenkoning: "rk",
  ruitenheer: "rk",
  ruitenkoningin: "rv",
  ruitenvrouw: "rv",
  ruitenboer: "rb",
  ruiteraas: "ra",
  ruiterkoning: "rk",
  ruiterheer: "rk",
  ruiterkoningin: "rv",
  ruitervrouw: "rv",
  ruiterboer: "rb",
  schoppenaas: "sa",
  schoppenkoning: "sk",
  schoppenheer: "sk",
  schoppenkoningin: "sv",
  schoppenvrouw: "sv",
  schoppenboer: "sb",
  hartenaas: "ha",
  hartenkoning: "hk",
  hartenheer: "hk",
  hartenkoningin: "hv",
  hartenvrouw: "hv",
  hartenboer: "hb",
  harteaas: "ha",
  hartekoning: "hk",
  harteheer: "hk",
  hartekoningin: "hv",
  hartevrouw: "hv",
  harteboer: "hb",
};
