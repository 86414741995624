import { combinedMapper, suitMapper, numberMapper } from "./";

const combinedRegexPattern = Object.keys(combinedMapper).join("|");
const suitsRegexPattern = Object.keys(suitMapper).join("|");
const numberRegexPattern = Object.keys(numberMapper).join("|");

export const playingCardRegex = new RegExp(
  `((?:${suitsRegexPattern})) ((?:${numberRegexPattern}))`,
  "gi"
);

export const combinedRegex = new RegExp(`((?:${combinedRegexPattern}))`, "gi");
